<template>
  <v-card elevation="0" outlined width="434px" class="card-border">
    <v-card-title><div class="title">{{ title }}</div></v-card-title>
    <v-card-text>
      <div id="ers-map" style="width:400px;height:400px;" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'KakaoMap',
  props: {
    title: { type: String, default: '' },
    center: { type: Object, default: () => { return null } },
    level: { type: Number, default: 4 },
    userMarker: { type: Object, default: () => { return null } },
    agentMarkers: { type: Array, default: () => { return [] } },
  },
  data() {
    return {
      defaultCenter: null,
      map: null,
      mapControl: null,
      zoomControl: null,
      container: null,
      markers: [],
      markerImage: {
        AN: new kakao.maps.MarkerImage('/images/marker/an-agent-96.png', new kakao.maps.Size(48, 48)),
        DD: new kakao.maps.MarkerImage('/images/marker/dd-agent-96.png', new kakao.maps.Size(48, 48)),
        DP: new kakao.maps.MarkerImage('/images/marker/dp-agent-96.png', new kakao.maps.Size(48, 48)),
        OF: new kakao.maps.MarkerImage('/images/marker/of-agent-96.png', new kakao.maps.Size(48, 48)),
        SB: new kakao.maps.MarkerImage('/images/marker/sb-agent-96.png', new kakao.maps.Size(48, 48)),
        TK: new kakao.maps.MarkerImage('/images/marker/tk-agent-96.png', new kakao.maps.Size(48, 48)),
      }
    }
  },
  methods: {
    initMap() {
      this.defaultCenter = new kakao.maps.LatLng(37.566826, 126.9786567)
      this.container = document.getElementById('ers-map')
      const options = {
        level: this.level,
        center: this.currentCenter
      }
      this.map = new kakao.maps.Map(this.container, options)
      this.mapControl = new kakao.maps.MapTypeControl()
      this.map.addControl(this.mapControl, kakao.maps.ControlPosition.TOPRIGHT)
      this.zoomControl = new kakao.maps.ZoomControl()
      this.map.addControl(this.zoomControl, kakao.maps.ControlPosition.RIGHT)
      kakao.maps.event.addListener(this.map, 'zoom_changed', this.onZoomChange)
    },
    setCenter() {
      this.map.panTo(this.currentCenter)
      this.setMarkers()
    },
    clearMarkers() {
      this.markers.forEach(m => {
        m.setMap(null)
      })
      this.markers = []
    },
    setMarkers() {
      this.clearMarkers()
      const loc = this.userMarker ? new kakao.maps.LatLng(this.userMarker.latitude, this.userMarker.longitude) : this.currentCenter
      this.markers.push(
        new kakao.maps.Marker({
          position: loc,
          title: '구조자'
        })
      )
      this.agentMarkers.forEach(e => {
        this.markers.push(
          new kakao.maps.Marker({
            position: new kakao.maps.LatLng(e.latitude, e.longitude),
            image: this.getMarkerImage(e.status),
            title: e.name
          })
        )
      })
      this.markers.forEach(e => {
        e.setMap(this.map)
      })
    },
    getMarkerImage(status) {
      return status ? this.markerImage[status] : this.markerImage.OF
    },
    onZoomChange() {
      this.$emit('zoom', this.map.getLevel())
    }
  },
  mounted() {
    this.initMap()
  },
  computed: {
    currentCenter() {
      return this.center ? new kakao.maps.LatLng(this.center.latitude, this.center.longitude)
        : this.defaultCenter
    }
  },
  watch: {
    center() {
      this.setCenter()
    },
    agentMarkers() {
      this.setMarkers()
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: bold;
}
.card-border {
  border: 2px solid black;
}
</style>
